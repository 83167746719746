@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'DNA';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
}

:root {
	/* Global CSS variables. */
	--dna-vedensininen: #37424a;
	--dna-meloninkeltainen: #ffa100;
	--dna-kivenharmaa: #4b5055;
	--dna-vaaleanharmaa: #e0e1dd;
	--dna-musta: #000000;
	--dna-puhdasvalkoinen: #ffffff;
	--dna-kuumapinkki: #d52b1e;
	--dna-backgroundgray: #f2f2f2;
	--dna-textcolor: #434a54;
}

*,
::before,
::after {
	font-family: "DNA";
	box-sizing: border-box;
	border-width: 0;
	/* border: 1px solid red; */
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	max-height: 100%;
	background-color: #f7f7f7;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--dna-backgroundgray);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card {
	background-color: var(--dna-puhdasvalkoinen);
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}